

.opi-download-button .icon {
  padding-right: 8px;
  height: 32px;
}

.opi-download-button .icon.animated {
  animation: icon-bounce 0.5s infinite alternate;
}

.opi-download-button:hover>.icon {
  animation: none;
}

.opi-download-button:hover>.text.animated {
  animation: text-fade 0.5s infinite alternate-reverse;
}

@keyframes text-fade {
  from {
    color: #819eff;
  }
  to {
    color: #FFFFFF;
  }
}

@keyframes icon-bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2px);
  }
}